import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo";
import SideBar from '../components/SideBar/SideBar';
import { isSSR } from '../utils/utils';

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo5MTgx") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const TmFaq = () => {
  const data = useStaticQuery(getdata);
  const common = data.wpgraphql.page;
  const [updatedArticleContent, setUpdatedArticleContent] = useState([]);
  // after finish load
  //
  useEffect(() => {
    if (isSSR()) {
      return;
    }
    //
    const items = document.querySelectorAll('.accordion button');
    items.forEach((item) => item.addEventListener('click', function () {
      const itemToggle = this.getAttribute('aria-expanded');

      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
      }

      if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }));
  }, []);
  return (
    <Layout>
      <Seo title="Trademark FAQs" description={common.seo.metaDesc} canonical={common.uri} seo={common.seo} />
      <div className="main-bg">
        <div className="overlay"></div>
        <img src="/assets/images/faq.jpg" alt="Trademark FAQs" />
        <h1>Trademark Faqs</h1>
      </div>

      <div className="row">
        <div className="col-md-9">
          <div className="container1">
            <div className="accordion">
              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span className="accordion-title">What Is A Trademark?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>
                    Trademarks are intellectual properties that include phrases, words, or symbols that identify or distinguish
                    your company’s source from others. In addition, trademarks protect brand names and logos used on goods or
                    services.
                  </p>
                  <p>A service mark is similar to a trademark, but it distinguishes and identifies the source of the service
                    rather than the goods.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Probability Of Confusion With Other Trademarks</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <img src="../assets/images/idea-2009484_1280-1-1024x702-1.jpg" alt="" />
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Similarity Of Goods And Service?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Three factors are more important when proving similarity of goods and services. First, the product’s
                    purpose and nature, then the use method, and finally, the distribution channels. These three factors must be
                    examined in relation to the target audience.</p>
                  <p>Even if two marks are confusingly similar, confusion will only exist if the goods or services that are
                    connected with them are related.
                  </p>
                  <p>The commercial relationship between the goods/services and those in the registration/an earlier-filed
                    request can help determine the relatedness of the goods/services.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Difference Between A Strong And A Weak Trademark?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>It is important to have strong trademarks that are distinctive. This allows you to identify yourself as the
                    source of your goods and services quickly. Your trademark will be stronger and more difficult to prevent
                    others from using your trademark without your permission. Because they lack the same legal protections, weak
                    trademarks can be costly and difficult to defend.</p>
                  <p>
                    Some marks are more difficult to protect than others described as either strong or weak marks. For example,
                    a strong mark can be used to stop third-party misuse of your mark. However, it is descriptive, meaning that
                    others have already used it to describe goods and services. This makes it expensive and difficult to police
                    and protect.
                  </p>
                  <p>
                    Strong trademarks are suggestive, fanciful or arbitrary. Weak trademarks can be descriptive or generic.</p>
                  <p>Fanciful and arbitrary marks are the most robust and protected types of marks. They are distinctive and
                    inherently unique. Fanciful marks refer to words that have no known dictionary meaning. Arbitrary marks are
                    words with a known meaning without association/relationship with the protected goods. Fanciful or arbitrary
                    marks can be registered and are more likely than descriptive ones. These marks are also creative and
                    uncommon, so it is less likely that other people are using them.
                  </p>
                  <h3>Examples Of Arbitrary And Fanciful Marks:</h3>
                  <ul>
                    <li>Fanciful: BELMICO offers “insurance services.”</li>
                    <li>Arbitrary: BANANA to “tires.”</li>
                  </ul>
                  <p>Suggestive marks suggest qualities or a connection between the goods and services. These marks can be
                    registered and are considered strong marks. A suggestive mark is the best alternative to a fanciful,
                    arbitrary mark.</p>
                  <h3>Examples For Suggestive Marks:</h3>
                  <ul>
                    <li>QUICK N’ NEAT for “pie crust.”</li>
                    <li>GLANCE-ADAY for “calendars.”</li>
                  </ul>
                  <p>Descriptive marks are words and designs that describe goods or services (e.g., a depiction on a TV for
                    “television repair service”). These marks are more difficult to protect than arbitrary and fanciful marks.
                    If the USPTO decides that a mark is “merely descriptive,” it will not be registrable or protected on
                    Principal Register unless it gains distinctiveness. This is usually achieved through prolonged use in
                    commerce for five years or more. Until distinctiveness is achieved, descriptive marks are considered weak.
                    Many applicants choose descriptive marks (often at the suggestion of marketing professionals) for their
                    goods or services. They believe that these marks reduce the need to educate and advertise consumers and make
                    it easier to identify the product or service directly from the mark. Even though this is a good marketing
                    strategy, it can lead to many unprotected trademarks. A descriptive mark might not be protected against
                    other users of similar or identical marks. Adopting a descriptive trademark could end up costing you more in
                    the long term. This is either because the legal costs of trying to enforce and police the mark are higher or
                    because the law may require that the descriptive mark be removed and replaced with a different mark.</p>
                  <h3>Examples For Descriptive Marks:</h3>
                  <ul>
                    <li>CREAMY is for “yogurt.”</li>
                    <li>WORLD’S BEST BAGELS for “bagels.”</li>
                  </ul>
                  <p>Generic words, which are not registered or enforceable against third parties, are the weakest type of
                    “marks.” Generic words used daily to identify goods and services are not protected because they are common
                    names for those goods and services. You cannot prevent others from using your generic term to identify
                    products and services. Even a strong mark can become generic over time if its owner uses it in a
                    non-trademark way (see ASPIRIN and ESCALATOR examples below) or fails to properly police the use of the mark
                    and take the appropriate actions. For example, if the original mark owner fails to police its use over time,
                    it could lose all trademark rights.</p>
                  <h3>Examples For Generic Marks:</h3>
                  <p>Applied-for trademarks would be considered generic when filed because they are the name for the product or
                    good offered by the service.</p>
                  <ul>
                    <li>BICYCLE is for “bicycles” and “retail bike stores.”</li>
                    <li>MILK is “a dairy-based beverage.”</li>
                    <li>Because of non-commercial long-term use, trademarks eventually became generic.</li>
                    <li>ESCALATOR to “move staircases,” ASPIRIN for “pain relief medication.”</li>
                  </ul>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Which Are Other Reasons Why The USPTO Might Refuse To Register?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Another reason for refusing the application is a surname, geographically descriptive of origin of
                    goods/services; disparaging and offensive; a foreign word that translates into a descriptive term or generic
                    term; an individual’s name or likeness; title of one book/movie; or matter that is solely ornamental. Some
                    of these rejections are absolute blocks to registration. However, evidence may be available in certain
                    circumstances.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Why Do I Need To Search For A Trademark?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <img src="../assets/images/search-bar-4999181_1280-1024x359-1.jpg" alt="" />
                  <p>It is significant to conduct a thorough search of your mark before applying. The results could reveal
                    potential problems such as confusion with a previously registered or pending mark. In addition, you could
                    avoid the hassle of applying for a registration for a mark that you may not be eligible for, as another
                    party may have greater rights to it.</p>
                  <p>The search results might also show whether your mark or part thereof appears in generic or descriptive
                    words in other registrations. This could indicate that your mark is weak or difficult to protect.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Do I Need Federal Registration For My Mark?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>No. To obtain protected rights in the United States, parties do not need to register their trademarks.
                    Instead, a mark can be granted “common law” rights based on its use in commerce. Federal trademark
                    registration on Principal Register offers many advantages over common law rights, including:</p>
                  <p>The legal application that you own the mark and exclusive rights to use it nationwide in connection with
                    the goods/services registered. Whereas a state registry gives rights within the boundaries of one state and
                    common law rights cover the area where the mark is used.</p>
                  <p>Use U.S. registration to get registration in other countries. It is best to include a designation whenever
                    you use your trademark. For example, use an (R) after your mark if it is already registered. To indicate
                    that the mark has been adopted as a trademark or service mark, you can use TM for goods or SM to indicate
                    that it is not yet registered. The registration symbol may only be used with the registered mark and the
                    goods/services recorded in the federal trademark registry. There are no superscript requirements.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is A Filing Date And How Is It Determined?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>Each application is assigned a filing date. If your application is transmitted via the Internet, the filing
                    day is when the transmission reaches the USPTO server in Eastern Standard Time. If you apply on paper, the
                    filing day of the application is the date that the USPTO received it.
                  </p>
                  <p>To determine priority, the USPTO uses a filing date. The USPTO reviews all applications as they were
                    received. The filing date is crucial because it prioritizes your application over later applications. If an
                    application is filed after yours, that could cause confusion with yours will be blocked or technically
                    suspended until your application registers or is abandoned. However, a filing date does not guarantee
                    priority.</p>
                  <p>Even though this is your first federal registration, you might still have substantial rights due to the
                    long-term use of your trademark. For example, suppose you are second in the line but have more substantial
                    rights than the applicant. In that case, you might be able to intervene in an opposition proceeding to stop
                    that application from being registered. While examination will not be performed in an orderly fashion, the
                    second-in-line has the right to assert special rights at the appropriate time during the overall
                    registration process.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Do I Need To Be A U.S Citizen To Apply?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>No. You don’t have to be a U.S. citizen to apply for and receive a federal registration.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Depiction Of The Mark (“The Drawing”)?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <img src="../assets/images/pencil.jpg" alt="" />
                  <p>Each application must contain a clear image (the drawing) of one mark. The USPTO uses this drawing to
                    upload the mark to the USPTO’s search database and print it in the Official Gazette. Each application for
                    registration of variations of the mark you want to register is unique and requires a separate fee. There are
                    two types: “standard form” and “special type.”</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is A “Standard Character Drawing”?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A standard character drawing protects only words, letters, and numbers. Standard character marks protect
                    the wording but do not limit the mark to any particular font, style, size, or color. This gives you more
                    excellent protection than unique form drawings.
                  </p>
                  <p>These characteristics must be present in a standard character drawing:</p>
                  <ul>
                    <li>No design element;</li>
                    <li>There is no stylization of numbers and letters;</li>
                    <li>All letters and words should be in Latin Character</li>
                    <li>Any number in Roman numerals or Arabic numerals</li>
                    <li>Only use standard punctuation and diacritical marks</li>
                  </ul>
                  <p>The system will generate a standard character drawing for your electronic filing. It is based on the
                    information that you have entered here.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is A “Special-Form” Drawing?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A special form drawing protects a particular design that may include colors, words or characters.
                  </p>
                  <p>You must choose the “special form” drawing format if your mark contains a design or logo. If you wish to
                    register a combination of words and design elements, the drawing must show both the word(s) as well as the
                    combined image.</p>

                  <p>Upload an image of the mark when you file electronically. The mark image should be in.jpg format. There
                    should be minimal white space around the mark’s design. The trademark, service mark, or registration symbols
                    (TM and SM) should not be included in mark images.</p>
                  <p>You must file on standard letter-size paper. Include the name of the applicant and the address of
                    correspondence at the top of the drawing page. The entire mark must be included on the page. It should
                    appear in the middle of each page.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Do I Submit A Black And White Drawing Or A Colored Drawing?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A black-and-white drawing can be submitted even if your mark is in color. This is because a black and white
                    drawing allows you to use any color of your mark. If you want your customers to associate specific colors
                    with your product, you might limit your mark and claim these colors as part of your mark. You must submit a
                    claim for color.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Requirements Do I Need To Submit A Color Drawing Form?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>The following must be submitted: (1) A “color claim” that names the color(s) and states that they are a
                    feature of your mark; (2) a separate statement that describes the mark and states where it appears in the
                    mark.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Difference Between Services And Goods?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Goods are products such as candles or bicycles. Services are activities performed for someone else’s
                    benefit, such as catering or bicycle rental.</p>
                  <p>It can be challenging to differentiate between services and goods. For example, is your customer paying you
                    for a particular product or service? For example, you have goods if your customer pays you for a product
                    such as a bicycle or a candle. On the other hand, if your customer pays you for an activity such as catering
                    or renting a bicycle, you are considered to be providing services. In an application, you can list either
                    goods or services.</p>
                  <p>It is necessary to list the goods/services you wish to register your trademark. If you file based on “use
                    in commerce,” the mark must be used on all listed goods/services. If you file based on a “bona fide
                    intention to use” the mark, you must have good faith or bona fide intentions to use it on all
                    goods/services.</p>
                  <p>You must describe your goods/services accurately in any entry you make. You may be denied registration of
                    your mark if you fail to accurately list the goods/services you use or intend to use. There will be no
                    payback for fees paid.</p>
                  <p>You should not select an entry that does not accurately list your goods/services. Instead, create your
                    identification. Use clear and concise terms that are easy to understand by the general public. Your
                    application will be void if you use vague terms such as “miscellaneous service” or “company name.” You must
                    submit a new one.</p>
                  <p>
                    You must select your goods/services in the ID Manual using the TEAS PLUS Form. Before you fill out the form,
                    ensure to read the ID Manual. You cannot use the TEAS PLUS Form if your ID Manual entry does not accurately
                    identify your goods/services. You can still use the regular TEAS Form.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Can I Modify The Goods/Services After I Have Filed My Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>While you can clarify or limit the services/goods, you cannot expand the goods/services. For example, if
                    you file for “shirts,” you can limit the goods and services to certain types of shirts, such as sweatshirts
                    or t-shirts. However, you cannot change the goods to other types of jewelry like earrings.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Does Basis For Filling Entails?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Your “basis” must be stated in the application. Most U.S. applicants base the application on their current
                    use of the trademark in commerce and their plans to use it.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is The Difference Between An “Intent To Use” And A “Use In Commerce”
                    Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Either way, you will need to prove that you have used your mark in commerce with the goods/services you are
                    applying for by submitting a specimen. These filing bases differ because you must show that you have used
                    the mark on all goods and services.</p>
                  <p>You can apply the “use in commerce” basis if your mark has been used for all of the goods/services
                    mentioned in your application. You must file under “intent to use” if you haven’t yet used your mark but
                    plan to do so in the future.</p>
                  <p>An “intent to use” basis requires filing an additional form and a fee. This is not required if you filed
                    under “use in commerce.” You can also apply where some goods/services have been used, and others are
                    intended to use. However, the application must indicate this.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Does It Mean To Use-In-Commerce?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You must use the mark for applications filed under the use in commerce basis. In addition, the mark must be
                    on goods, such as the tags, labels, containers, and displays associated with them. Finally, the mark must
                    also be used to promote or sell services.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">How Do I Establish My “Use-In-Commerce” Basis?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Indicate the date of the first use of your mark anywhere and the date you used it in commerce.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What’s The Difference Between “Date Of The First Use Anywhere” Or “Date Of The
                    First Use In Commerce?”</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>The date of the first usage anywhere is when the goods or services were first offered under the mark, even
                    if this was a local sale or transport. The date of first commerce use is when the goods or services were
                    first offered under the mark in commerce between more than one U.S. state or territory or between U.S.
                    states and other countries. The date of the first usage anywhere must be the same or earlier than the first
                    use in commerce.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Does It Mean To Have An “Intent–To-Use” Basis?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You may file a bona fide or good faith intent to use the trademark in business if you haven’t used the mark
                    yet but intend to. Bonafide intent is more than an idea. It’s not market-ready. A bona fide plan to use the
                    mark could be reflected in a business plan, sample products, or other initial business activities.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Do You Know Of Any Other Filing Bases?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Yes. Based on international agreements/treaties, an owner may apply to the U.S. based on a foreign
                    application/registration issued by another country (a “Section 44” application). In addition, a foreign
                    owner can also file an international application in their home country to request protection extension to
                    the U.S. (a Section 66(a).) For more information, see Trademark Manual of Examining Procedures (TMEP)
                    Chapters 1000 or 1900.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">How Can I Protect My Mark In The United States?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>The USPTO registration only applies to the United States, even though the goods/services have been assigned
                    an international classification number. However, you can file an international application if you wish to
                    protect your trademark outside the U.S. For more information about protecting your mark under the Madrid
                    Protocol, see http://www.wipo.int/madrid/en/.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Does A “Specimen Of Use” Mean?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A specimen is a sample that shows how the mark is used in commerce for your goods and services. The drawing
                    is different from the specimen. A drawing only shows your mark, while a specimen depicts the mark as your
                    buyers encounter it (e.g., on labels or your website).</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Constitutes A Good Example For The Use Of A Mark On Goods Or
                    Products?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A specimen of a mark on goods usually shows the mark exactly as it appears on the goods or packaging. Your
                    specimen could be either a label or tag displaying the mark or a photograph of the goods or packaging.</p>
                  <p>These items may not be “mock-ups,” but the specimen must show what you use and a photo of the packaging. It
                    is not enough to display the mark; the specimen must also show the mark directly related to the goods.</p>
                  <p>An acceptable specimen may not include a specimen showing the mark used only in an ornamental or decorative
                    way. For example, it is unlikely that a slogan or design on a t-shirt, any other clothing, or a bag would be
                    accepted as it will be perceived as decorative or ornamental rather than a trademark. On the other hand, a
                    small word or design on a shirt pocket might be a trademark and accepted as a specimen.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">Is My Site A Good Example Of A Product?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Websites are acceptable specimens if the mark is located near the image of the goods or a text description
                    of them. Customers can also order from the website. Websites that only advertise the goods are not
                    acceptable. Additionally, it is unacceptable to provide a screenshot of the website.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is NOT An Appropriate Specimen For Goods?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Invoices and announcements, orders forms, leaflets, brochures, or publicity releases are good examples of
                    goods.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Constitutes A Good Example For The Use Of A Mark That Includes
                    Services?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>A specimen of a mark used in connection to services must include the mark used for providing or advertising
                    those services. For example, your specimen could be a photograph of a sign at a business, a brochure about
                    services, an advertisement for services, a website, webpage, business card, or stationery displaying the
                    mark. In addition, it must contain some reference to services.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is NOT An Appropriate Specimen For Services?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Printing proofs of your services in advertisements and news articles are not acceptable as they don’t show
                    the use of the mark.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Should I Do After I Have Filed My Application?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You must monitor your application’s status with diligence. This means that you should:</p>
                  <p>Regularly check the status of your pending applications. It is recommended that you review the status of
                    your application every 3-4 months. Your application will be evaluated by the assigned examining lawyer
                    within three months of its filing date.</p>
                  <p>The registration process can extend over to one year or longer depending on many factors, such as the basis
                    for applying and whether the application was complete at the time it was filed. Here is a timeline of the
                    general process for an application filed on an intent-to-use basis. This is what our diamond package does
                    automatically.</p>
                  <p>Within the time frames required, respond to any Office action or notice. Generally, this is within six
                    months from the date of issue.</p>
                  <p>If you find a mistake in the file, contact the USPTO immediately and ask for corrective action.</p>
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Happens After Filing And What To Do?</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>The application will be allocated to an examining lawyer to determine if federal law allows registration.
                    This happens approximately three months after the filing date. To ensure compliance with federal law, the
                    examining attorney will review the application in writing, drawing, and specimen.</p>
                  <p>An Office action is a written communication from the examining attorney explaining why registration was
                    denied or other requirements. You must respond within six months after receiving an Office action. If the
                    application is denied registration, your filing fee will not refund.

                  </p>
                  <img src="../assets/images/laptop-2589420_1280-3-1024x681-1.jpg" alt="" />
                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Is Publication To Opposition?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Publication to opposition is when there are no additional requirements or refusals, or all issues are
                    resolved, and the examining lawyer approves publication of the mark in the Official Gazette, a weekly online
                    publication. You will receive a Notice to Publication from the USPTO indicating the publication.</p>
                  <p>The USPTO will e-mail you a notification of ‘Notice of Publication’ approximately three weeks before the
                    publication date in the OG if you have allowed e-mail communication. You will be e-mailed a second e-mail on
                    the publication date. This is “Official Gazette Publication Confirmation.” It contains a link to the OG.

                  </p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What Happens After Publication?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>The public has a 30-day window to object to the registration by filing an objection after publication in
                    the OG. A Trademark Trial and Appeal Board is the USPTO administrative tribunal handling oppositions.
                    Opposition is very similar to a court proceeding. An opposition request may be filed by a third party
                    interested in filing an opposition. This could delay any further action.</p>
                  <p>The next step is dependent on the basis you used to apply.

                  </p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What To Do In Case Of Registration Code Issues For “Use In-Commerce”
                    Application?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You don’t require to do anything if there is no opposition or extension of the deadline to oppose or you
                    have overcome an objection. The USPTO will generally issue a registration certificate within 11 weeks of
                    publication if there are no opposition-related filings. This is true if the application is based on the
                    actual use in commerce or on a foreign registration.

                  </p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What To Do Incase Of Notice Of Allowance (NOA), Issues For “Intent To-Use”
                    Application?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You don’t require to do anything to allow your application to move on to the next stage if there is no
                    opposition or extension of the deadline to oppose. The USPTO will generally issue an NOA within eight weeks
                    of publication if there are no opposition-related filings.

                  </p>
                  <p>The NOA signifies that your mark is allowed but not registered. You must complete the following steps to
                    register within six months from the date of the NOA’s issue:</p>
                  <ul>
                    <li>If you have filed based upon intent to use and use the mark in commerce, submit a “Statement Of Use.”
                    </li>
                    <li>Start using the mark in commerce, then submit a Statement of Use.</li>
                    <li>If you require additional time to use the mark in commerce, submit a “Request for an Addition of Time to
                      File a Statement Of Use” within six months.</li>
                  </ul>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">How To Establish Use Of The Mark For An “Intent-To-Use” Application?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>Notification of Allowance (NOA), Has Already Been Issued

                  </p>
                  <p>You can establish use if an NOA has been issued by filing a Statement Of Use (SOU), which contains a sworn
                    declaration that you are using the mark in commerce for all goods/services. You must remove or divide the
                    goods/services not in use if you want to file an SOU.</p>
                  <p>The SOU must also contain:</p>
                  <ul>
                    <li>There is a $100 filing fee per category of goods/services.</li>
                    <li>The date of first usage of the mark anywhere and the date of its first use in commerce.</li>
                    <li>A single example (or specimen) of how the mark is used in commerce for each type of goods/service.</li>
                  </ul>
                  <p>You have six months from the date the USPTO issues your NOA to file the SOU. The six-month period starts
                    from the date the NOA was issued, not when you received it. You must file a plea for an extension of time to
                    file a statement of use (Extension Request) if you have not used your mark in commerce within six months.
                  </p>
                  <p>Otherwise, the application will be declared abandoned, and your mark will no longer register. Additional
                    extensions may be requested for up to 5 months after the NOA issue date. Each Extension Request must be
                    accompanied by a $150 filing fee per class of goods/services. The deadline to file the subsequent Extension
                    Request or the SOU does not change based on the date of grant or denial. The NOA issue date is used to
                    calculate the deadline</p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">What to do if Not Yet Issued Notice Of Allowance (NOA)?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You can file an Amendment to Allege usage if the NOA hasn’t been issued yet and the application hasn’t yet
                    been published. This includes the same information that the SOU (see above). The Amendment to Allege Use
                    must be filed within the “blackout” period after publication of the mark and before the issuance of the NOA.
                    You must file your SOU after the blackout period in such cases.</p>

                </div>
              </div>

              <div className="accordion-item">
                <button id="accordion-button-1" aria-expanded="false">
                  <span className="accordion-title">How To Maintain A Federal Trademark Registration?
                  </span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">

                  <p>You must file your first maintenance paper between 5 and 6 years after your registration date. All
                    subsequent maintenance documents should be filed within the same period. Important information about
                    maintaining your federal registration is contained in your registration certificate. Unfortunately, the
                    USPTO doesn’t send reminder notices to remind you when documents are due. Failure to timely file the
                    documents will result in your registration being canceled. This means that you cannot have your registration
                    reinstated or revived.</p>
                  <p>You must enforce and police your rights throughout the registration’s life. The USPTO will stop any other
                    pending application for similar marks on related goods and in connection to related services from proceeding
                    with registration based upon a finding of likelihood of confusion. However, the USPTO won’t engage in
                    separate policing or enforcement activities.</p>
                  <p>If you use the trademark and file the necessary maintenance documents along with the applicable fees at the
                    correct times, your rights in a federally registered mark can last indefinitely. These documents are
                    required to maintain a trademark registration:</p>
                  <ul>
                    <li>Declaration of continued utilization or excusable nonuse under Section 8 (SS8 Declaration);</li>
                    <li>Combined Declaration of Continued Usage and Application for Renewal under Sections 8 and 9 (combined
                      SSSS8 &amp; 9).</li>
                    <li>An SS8 declaration must be filed before the expiration of the 6-year grace period or the registration
                      date. The registration will be canceled if the declaration is not filed.</li>
                    <li>A combined SSSS8/9 must be filed within six months of the expiration of the 6-month grace period.
                      Failure to file these filings can result in the cancellation or expiration of your registration.</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ paddingTop: 30 + 'px' }}>
          <SideBar></SideBar>
        </div>
      </div>

    </Layout>
  );
};

export default TmFaq;
